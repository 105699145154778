import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "highlightpipe",
})
export class HighlightPipe implements PipeTransform {
  transform(value: any, searchText: string): any {
    if (!searchText) {
      return value;
    }

    const regex = new RegExp(searchText.toLowerCase(), "gi");
    const match = value.match(regex);

    if (!match) {
      return value;
    }
    return value.replace(regex, `<mark class='highlight'>$&</mark>`);
  }
}
