import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { ImagePreloaderService } from "../../../shared-components/src/app/services/image-preloader.service";
import { CommonService } from "./services/common.service";

@Injectable({
  providedIn: "root",
})
export class AppResolver implements Resolve<any[]> {
  constructor(
    private imgPreloadService: ImagePreloaderService,
    private commonService: CommonService
  ) {}

  preloadSciFocusImages: string[] = [
    "assets/img/Background/all-image1.webp",
    "assets/img/Background/astrophysics-image1.webp",
    "assets/img/Background/biophysical-image1.webp",
    "assets/img/Background/earthScience-image1.webp",
    "assets/img/Background/heliophysics-image1.webp",
    "assets/img/Background/planetary-image1.webp",
    "assets/img/Background/all-image2.webp",
    "assets/img/Background/astrophysics-image2.webp",
    "assets/img/Background/biophysical-image2.webp",
    "assets/img/Background/earthScience-image2.webp",
    "assets/img/Background/heliophysics-image2.webp",
    "assets/img/Background/planetary-image2.webp",
  ];

  preloadSearchPageImages: string[] = [
    "assets/img/navbar-all.webp",
    "assets/img/navbar-earth-science.webp",
    "assets/img/navbar-planetary.webp",
    "assets/img/navbar-astrophysics.webp",
    "assets/img/navbar-bio-physical.webp",
    "assets/img/navbar-heliophysics.webp",
  ];

  preloadSearchStoryImages: string[] = [
    "assets/img/search-story/blue_yellow_gradiant.webp",
    "assets/img/search-story/red_gradiant.webp",
    "assets/img/search-story/brown-gradiant.webp",
    "assets/img/search-story/Ashish_Acharya.webp",
    "assets/img/search-story/BC_RAY.webp",
    "assets/img/search-story/Eli_Walker.webp",
    "assets/img/search-story/Kaylin_bugbee.webp",
    "assets/img/search-story/blue_gradiant.webp",
    "assets/img/search-story/Emily_Foshee.webp",
  ];

  preloadSearchStoryMobileImages: string[] = [
    "assets/img/search-story/mobile_red_gradiant.webp",
    "assets/img/search-story/mobile_blue_gradiant.webp",
    "assets/img/search-story/mobile_brown_gradiant.webp",
    "assets/img/search-story/mobile_blue_yellow_gradiant.webp",
    "assets/img/search-story/Ashish_Acharya.webp",
    "assets/img/search-story/BC_RAY.webp",
    "assets/img/search-story/Eli_Walker.webp",
    "assets/img/search-story/Kaylin_bugbee.webp",
    "assets/img/search-story/Emily_Foshee.webp",
  ];

  resolve(route: ActivatedRouteSnapshot): Promise<any[]> {
    if (route.routeConfig?.path === "MySearchStory") {
      if (this.commonService.isMobileDevice()) {
        return this.imgPreloadService.preload(
          this.preloadSearchStoryMobileImages
        );
      } else {
        return this.imgPreloadService.preload(this.preloadSearchStoryImages);
      }
    } else if (route.routeConfig?.path === "search") {
      return this.imgPreloadService.preload(this.preloadSearchPageImages);
    } else {
      return this.imgPreloadService.preload(this.preloadSciFocusImages);
    }
  }
}
