import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { SearchService } from "@sinequa/components/search";
import { UIService } from "@sinequa/components/utils";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import googleAnalyticsConstants from "sde/src/app/analytics/google-analytics.constant";
import { ImagePreloaderService } from "shared-components/src/app/services/image-preloader.service";
import { SharedCommonService } from "../../../../../../shared-components/src/app/services/shared-common.service";
import { SearchStoryDataService } from "./../../../services/search-story-data.service";

@Component({
  selector: "app-search-story",
  templateUrl: "./search-story.component.html",
  styleUrls: ["./search-story.component.scss"],
})
export class SearchStoryComponent implements OnInit {
  searchStoryData: any;
  readMoreState: boolean[] = [];
  slideIntervals: any = [28000, 16000, 28000, 12000, 14000, 22000];
  currentInterval: any = this.slideIntervals[0];

  constructor(
    private searchStoryService: SearchStoryDataService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private searchService: SearchService,
    public ui: UIService,
    public imagePreloadService: ImagePreloaderService,
    private titleService: Title,
    private sharedCommonService: SharedCommonService,
    private $gaService: GoogleAnalyticsService
  ) {}

  ngOnInit(): void {
    this.initializePage();
    this.loadSearchStoryData();
    this.subscribeToRouteData();
  }

  initializePage() {
    this.sharedCommonService.globalNasaLoader.next(true);
    this.titleService.setTitle(
      googleAnalyticsConstants.currentPage.mySearchStory
    );
  }

  onSlideChange(newSlideIndex: any): void {
    this.currentInterval = this.slideIntervals[newSlideIndex];
  }

  loadSearchStoryData() {
    this.searchStoryService.getSearchStoryData().subscribe((data: any) => {
      this.searchStoryData = data.SearchStoryItems;
      this.initializeReadMoreState();
    });
  }

  initializeReadMoreState() {
    this.readMoreState = new Array(this.searchStoryData.length).fill(false);
  }

  subscribeToRouteData() {
    this.activatedRoute.data.subscribe((data) => {
      if (data) {
        this.sharedCommonService.globalNasaLoader.next(false);
      }
    });
  }

  setImagesForStory(images) {
    let image = this.imagePreloadService.getImage(images);
    return image?.src;
  }

  goToHomepage() {
    this.router.navigate(["/home"]);
    this.$gaService.event(
      googleAnalyticsConstants.action.click,
      googleAnalyticsConstants.category.button,
      googleAnalyticsConstants.label.close,
      0,
      true,
      {
        app_name: this.sharedCommonService.getAppDetailsForGA().app_name,
        page: googleAnalyticsConstants.currentPage.mySearchStory,
        url: window.location.href,
        route: this.router.url,
      }
    );
  }

  goToResultsPage(text) {
    this.searchService.query.text = text;
    this.searchService.query.tab = "Data";
    this.searchService.searchText("search");
    this.sharedCommonService.trackSearchEventForGoogleAnalytics(
      this.searchService.query.scope ?? "ALL",
      this.sharedCommonService.getAppliedFilterValues()
    );
  }

  toggleReadMore(i) {
    this.readMoreState[i] = !this.readMoreState[i];
  }

  submitYours() {
    window.open("https://forms.gle/xi4Wx5Mx9RxMirwH7", "_blank");
    this.$gaService.event(
      googleAnalyticsConstants.action.click,
      googleAnalyticsConstants.category.button,
      googleAnalyticsConstants.label.submitYours,
      0,
      true,
      {
        app_name: this.sharedCommonService.getAppDetailsForGA().app_name,
        page: googleAnalyticsConstants.currentPage.mySearchStory,
        url: window.location.href,
        route: this.router.url,
      }
    );
  }
}
