<div>
  <ng-container *ngIf="!noEntities; else zeroEntities">
    <ng-container *ngFor="let entity of entities; let i = index">
      <app-sde-facet-card
        class="my-3"
        [title]="entity.display"
        [collapsible]="false"
        [buttonsStyle]="style"
        [type]="'entity-highlight'"
        (toggleSortEvent)="changeSortingOrder(i)"
        (toggleSelectAll)="changeSelectAllState(i)"
      >
        <app-sde-preview-entity-facet
          #facet
          [entity]="entity.type"
          [data]="entity.values"
          [previewData]="previewData"
          [preview]="preview"
          [allSelected]="selectAllStates[i]"
          [sortFreq]="sortStates[i]"
        >
        </app-sde-preview-entity-facet>
      </app-sde-facet-card>
    </ng-container>
    <div class="whole-div">
      <div class="legends-title font-family-rajdhani-semibold">Legends</div>
      <div class="legends-main-div">
        <div class="legends-container">
          <ng-container *ngIf="appName == 'SDE'">
            <div>
              <div class="legends-item">
                <div class="legend-box-one"></div>
                <div class="legends-text">Search Term</div>
              </div>

              <div class="legends-item">
                <div class="legend-box-two"></div>
                <div class="legends-text">Instruments</div>
              </div>
            </div>

            <div>
              <div class="legends-item">
                <div class="legend-box-three"></div>
                <div class="legends-text">Missions</div>
              </div>

              <div class="legends-item">
                <div class="legend-box-four"></div>
                <div class="legends-text">Platforms</div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="appName == 'TDAMM'">
            <div class="legends-container">
              <div>
                <div class="legends-item">
                  <div class="legend-box-one"></div>
                  <div class="legends-text">Search Term</div>
                </div>

                <div class="legends-item">
                  <div class="legend-box-tdamm-two"></div>
                  <div class="legends-text">Messengers</div>
                </div>
              </div>

              <div class="legends-item">
                <div class="legend-box-tdamm-three"></div>
                <div class="legends-text">Objects</div>
              </div>

              <div>
                <div class="legends-item">
                  <div class="legend-box-tdamm-four"></div>
                  <div class="legends-text">Signals</div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #zeroEntities>
  <p class="px-2 my-2 no-entities font-family-rajdhani-semibold">
    {{ "msg#preview.noentities" | sqMessage }}
  </p>
</ng-template>
