<div *ngIf="previewData" class="container-fluid">
  <!-- Title and download button -->
  <div class="top-section row px-4" #topSection>
    <div
      [ngClass]="{
        'col-xl-12 col-lg-12 col-md-12': previewType === 'expanded',
        'col-xl-8 col-lg-8 col-md-8 mr-2': previewType !== 'expanded'
      }"
      class="col-sm-12 mt-3 mb-3 record-name d-flex flex-column justify-content-center"
    >
      <div
        *ngIf="previewType !== 'expanded'"
        #ell
        ellipsis="..."
        [ellipsis-content]="previewData.record.title"
        class="preview-not-expanded"
        [sqTooltip]="previewData.record.title"
      ></div>
      <div
        *ngIf="previewType === 'expanded'"
        #ell
        ellipsis="..."
        [ellipsis-content]="previewData.record.title"
        class="preview-expanded"
        [sqTooltip]="previewData.record.title"
      ></div>
    </div>
    <div
      *ngIf="previewType !== 'expanded'"
      class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mt-3 mb-3 record-download-box d-flex justify-content-end align-self-start"
    >
      <ng-container *ngFor="let metadata of metadataViewerOptions">
        <img
          class="mx-2 preview-icon"
          tabindex="0"
          [sqTooltip]="metadata.tooltip | sqMessage"
          [src]="metadata.imageSrc"
          (click)="metadata.function()"
          (keyup.enter)="metadata.function()"
          [alt]="metadata.alt"
          *ngIf="!metadata.hide"
        />
      </ng-container>
    </div>
  </div>
  <div class="metadata-container">
    <div
      #metadataContent
      class="metadata-content"
      [ngClass]="{ 'mobile-width-metadata': isMobile }"
      cdkScrollable
    >
      <div class="metadata-overview">
        <div class="py-4 px-4 container-fluid main-div">
          <div class="mb-4 overview-grid">
            <div #leftSection class="mx-1 left-section" id="data">
              <div class="mt-2 px-2 py-2 right-item">
                <div class="title">Event ID</div>
                <div class="content font-family-inter-light">
                  {{
                    recordData && recordData.persistent_id
                      ? recordData.persistent_id
                      : recordData && recordData.id && recordData.id.split("|")
                      ? recordData.id.split("|")[1]
                      : "-"
                  }}
                </div>
              </div>
              <div class="mt-2 px-2 py-2 right-item">
                <div class="title">
                  Event Type
                  <span
                    (click)="openAcronyms(acronymsInfo)"
                    class="cursor-pointer"
                    ><img src="assets/img/acronyms-info.svg" alt=""
                  /></span>
                </div>

                <div
                  class="content font-family-inter-light"
                  [sqTooltip]="displayAcronymsValue?.cap_type"
                >
                  {{
                    recordData && recordData.cap_type
                      ? recordData.cap_type
                      : "-"
                  }}
                </div>
              </div>
              <div
                class="mt-2 px-2 py-2 right-item"
                *ngIf="isHeliophysicsEventsKnowledgebase()"
              >
                <div class="title">
                  Coordinate System
                  <span
                    (click)="openAcronyms(acronymsInfo)"
                    class="cursor-pointer"
                    ><img src="assets/img/acronyms-info.svg" alt=""
                  /></span>
                </div>
                <div
                  class="content font-family-inter-light"
                  [sqTooltip]="displayAcronymsValue?.spatial_bounds_coord_sys"
                  *ngIf="recordData && recordData.spatial_bounds_coord_sys"
                >
                  {{ recordData?.spatial_bounds_coord_sys }}
                </div>
                <div
                  class="content font-family-inter-light"
                  *ngIf="recordData && !recordData.spatial_bounds_coord_sys"
                >
                  -
                </div>
              </div>
              <div
                class="mt-2 px-2 py-2 right-item"
                *ngIf="isHeliophysicsEventsKnowledgebase()"
              >
                <div class="title">Coordinate Units</div>
                <div class="content font-family-inter-light">
                  {{
                    recordData && recordData.coord_unit
                      ? recordData.coord_unit
                      : "-"
                  }}
                </div>
              </div>
            </div>
            <div
              #middleSection
              class="overview-meta-group container"
              id="attributes"
            >
              <div>
                <div
                  class="mb-3 row"
                  *ngIf="isHeliophysicsEventsKnowledgebase()"
                >
                  <div class="col-sm-6 mt-2 px-2 py-2">
                    <p
                      class="my-1 overview-meta-title font-family-rajdhani-bold"
                    >
                      Instrument
                      <span
                        (click)="openAcronyms(acronymsInfo)"
                        class="cursor-pointer"
                        ><img src="assets/img/acronyms-info.svg" alt=""
                      /></span>
                    </p>
                    <div
                      *ngIf="recordData?.instrument"
                      class="my-1 font-family-inter-light overview-meta-content"
                    >
                      <div [sqTooltip]="displayAcronymsValue?.instrument">
                        {{
                          recordData?.instrument ? recordData?.instrument : "-"
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 mt-2 px-2 py-2">
                    <p
                      class="my-1 overview-meta-title font-family-rajdhani-bold"
                    >
                      Event Peak Time
                    </p>
                    <p
                      class="my-1 font-family-inter-light overview-meta-content"
                    >
                      {{
                        recordData?.temporal_bounds_3
                          ? recordData?.temporal_bounds_3
                          : "-"
                      }}
                    </p>
                  </div>
                </div>
                <div
                  class="mb-3 row"
                  *ngIf="isHeliophysicsEventsKnowledgebase()"
                >
                  <div class="col-sm-6 mt-2 px-2 py-2">
                    <p
                      class="my-1 overview-meta-title font-family-rajdhani-bold"
                    >
                      Observation Wavelength
                    </p>
                    <p
                      class="my-1 font-family-inter-light overview-meta-content"
                    >
                      {{
                        recordData?.mean_wavel ? recordData?.mean_wavel : "-"
                      }}
                    </p>
                  </div>
                  <div class="col-sm-6 mt-2 px-2 py-2">
                    <p
                      class="my-1 overview-meta-title font-family-rajdhani-bold"
                    >
                      Observation Wavelength Units
                    </p>
                    <p
                      class="my-1 font-family-inter-light overview-meta-content"
                    >
                      {{
                        recordData?.wavel_unit ? recordData?.wavel_unit : "-"
                      }}
                    </p>
                  </div>
                </div>
                <div
                  class="mb-3 row"
                  *ngIf="isHeliophysicsEventsKnowledgebase()"
                >
                  <div class="col-sm-6 mt-2 px-2 py-2">
                    <p
                      class="my-1 overview-meta-title font-family-rajdhani-bold"
                    >
                      Event Start Time
                    </p>
                    <p
                      class="my-1 font-family-inter-light overview-meta-content"
                    >
                      {{
                        recordData?.temporal_bounds_1
                          ? recordData?.temporal_bounds_1
                          : "-"
                      }}
                    </p>
                  </div>
                  <!-- End time -->
                  <div class="col-sm-6 mt-2 px-2 py-2">
                    <p
                      class="my-1 overview-meta-title font-family-rajdhani-bold"
                    >
                      Event End Time
                    </p>
                    <p
                      class="my-1 font-family-inter-light overview-meta-content"
                    >
                      {{
                        recordData?.temporal_bounds_2
                          ? recordData?.temporal_bounds_2
                          : "-"
                      }}
                    </p>
                  </div>
                </div>
                <div
                  class="mb-3 row"
                  *ngIf="isHeliophysicsEventsKnowledgebase()"
                >
                  <div class="col-sm-6 mt-2 px-2 py-2">
                    <p
                      class="my-1 overview-meta-title font-family-rajdhani-bold"
                    >
                      Detection Mode
                    </p>
                    <p
                      class="my-1 font-family-inter-light overview-meta-content"
                    >
                      {{ recordData?.frm_name ? recordData?.frm_name : "-" }}
                    </p>
                  </div>
                  <div class="col-sm-6 mt-2 px-2 py-2">
                    <ng-container *ngIf="recordData?.h_flag">
                      <p
                        class="my-1 overview-meta-title font-family-rajdhani-bold"
                      >
                        Human Identified
                      </p>
                      <p
                        class="my-1 font-family-inter-light overview-meta-content"
                      >
                        {{ recordData?.h_flag ? recordData?.h_flag : "-" }}
                      </p>
                    </ng-container>
                    <ng-container *ngIf="!recordData?.h_flag">
                      <p
                        class="my-1 overview-meta-title font-family-rajdhani-bold"
                      >
                        Algorithm Identified
                      </p>
                      <p
                        class="my-1 font-family-inter-light overview-meta-content"
                      >
                        true
                      </p>
                    </ng-container>
                  </div>
                </div>
                <div class="mb-3 row">
                  <div class="col-sm-6 mt-2 px-2 py-2">
                    <p
                      class="my-1 overview-meta-title font-family-rajdhani-bold"
                    >
                      Publication Date
                    </p>
                    <p
                      class="my-1 font-family-inter-light overview-meta-content"
                    >
                      {{
                        recordData?.pub_date
                          ? (recordData?.pub_date | sqDate : dateFormat)
                          : "-"
                      }}
                    </p>
                  </div>
                  <div class="col-sm-6 mt-2 px-2 py-2">
                    <p
                      class="my-1 overview-meta-title font-family-rajdhani-bold"
                    >
                      Event Observer
                    </p>
                    <p
                      class="my-1 font-family-inter-light overview-meta-content"
                    >
                      {{ recordData?.authors ? recordData?.authors : "-" }}
                    </p>
                  </div>
                </div>
                <div
                  class="mb-3 row"
                  *ngIf="isHeliophysicsEventsKnowledgebase()"
                >
                  <div class="col-sm-6 mt-2 px-2 py-2">
                    <p
                      class="my-1 details-meta-title font-family-rajdhani-bold"
                    >
                      Coordinates/Location
                    </p>
                    <div
                      class="my-1 font-family-inter-light details-meta-content"
                    >
                      <ng-container *ngIf="coordinatesLocationArray.length > 0">
                        <ng-container
                          *ngIf="coordinatesLocationArray.length === 1"
                        >
                          <p
                            class="my-1 font-family-inter-light details-meta-content"
                          >
                            {{ coordinatesLocationArray[0] }}
                          </p>
                        </ng-container>
                        <ng-container
                          *ngIf="coordinatesLocationArray.length > 1"
                        >
                          <ul
                            *ngFor="
                              let coordinatesLocation of coordinatesLocationArray
                            "
                            class="my-1"
                          >
                            <li>{{ coordinatesLocation }}</li>
                          </ul>
                        </ng-container>
                      </ng-container>
                      <ng-container
                        *ngIf="coordinatesLocationArray.length == 0"
                      >
                        <p>-</p>
                      </ng-container>
                    </div>
                  </div>
                  <div class="col-sm-6 mt-2 px-2 py-2">
                    <p
                      class="my-1 overview-meta-title font-family-rajdhani-bold"
                    >
                      Mission/Observatory
                      <span
                        (click)="openAcronyms(acronymsInfo)"
                        class="cursor-pointer"
                        ><img src="assets/img/acronyms-info.svg" alt=""
                      /></span>
                    </p>
                    <p
                      class="my-1 font-family-inter-light overview-meta-content"
                      [sqTooltip]="displayAcronymsValue?.mission"
                    >
                      {{ recordData?.mission ? recordData?.mission : "-" }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              #rightSection
              *ngIf="!isHeliophysicsEventsKnowledgebase()"
              class="mx-1 right-section"
              id="description"
            >
              <div class="left-item px-2 py-2">
                <div class="title">Event Summary</div>

                <ng-container>
                  <div
                    class="gcn-content font-family-public-sans-regular pb-2"
                    [innerHTML]="preprocessedContent"
                  ></div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="metadata-list" *ngIf="!isMobile">
      <div class="title" (click)="triggerScroll('data')">Table of Contents</div>
      <ul class="table-of-contents side-nav">
        <li>
          <a
            (click)="triggerScroll('data')"
            class="list-item font-family-rajdhani-bold"
            >Overview</a
          >
          <ul>
            <li
              class="list-item font-family-rajdhani-medium sub-item"
              [class.active]="activeSection === 'data'"
            >
              <a (click)="triggerScroll('data')">Data</a>
            </li>
          </ul>
        </li>
        <li *ngIf="isHeliophysicsEventsKnowledgebase()">
          <a
            (click)="triggerScroll('attributes')"
            class="list-item font-family-rajdhani-bold"
            >Details</a
          >
          <ul>
            <li
              class="list-item font-family-rajdhani-medium sub-item"
              [class.active]="activeSection === 'attributes'"
            >
              <a (click)="triggerScroll('attributes')">Attributes</a>
            </li>
          </ul>
        </li>
        <li *ngIf="!isHeliophysicsEventsKnowledgebase()">
          <a
            (click)="triggerScroll('description')"
            class="list-item font-family-rajdhani-bold"
            >Details</a
          >
          <ul>
            <li
              class="list-item font-family-rajdhani-medium sub-item"
              [class.active]="activeSection === 'description'"
            >
              <a (click)="triggerScroll('description')">Summary</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>

<ng-template #acronymsInfo>
  <div class="acronyms-info-container">
    <div class="acronyms-header">
      <div>
        <h2 class="title-text font-family-rajdhani-bold">Discover acronyms</h2>
      </div>
      <div>
        <button type="button" aria-label="Close" class="fixed-button">
          <img
            src="assets/img/filters-close-icon.svg"
            alt=""
            class="close-button"
            (click)="modalRef?.hide()"
          />
        </button>
      </div>
    </div>
    <div class="acronyms-body">
      <p>
        Click below to learn more about the acronyms used in HEK, GCN & more.
      </p>
      <button
        (click)="goToAcronyms()"
        class="learn-more font-family-rajdhani-medium"
      >
        Learn More
      </button>
    </div>
  </div>
</ng-template>
