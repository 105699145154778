<ng-container *ngIf="previewData">
  <div
    class="font-family-rajdhani-semibold related-documents-title p-2"
    *ngIf="!loading && similarNotDuplicates.length > 0"
  >
    Related Documents
  </div>

  <div
    class="text-center font-family-rajdhani-semibold py-3 no-data"
    *ngIf="!loading && similarNotDuplicates.length <= 0"
  >
    <span>{{ "msg#preview.norelateddocs" | sqMessage }}</span>
  </div>

  <div
    *ngIf="loading"
    class="d-flex justify-content-center align-items-center"
    style="height: 100vh"
  >
    <div class="spinner-grow" role="status"></div>
  </div>
</ng-container>

<ng-container *ngIf="!loading">
  <ol>
    <div
      *ngFor="let item of similarNotDuplicates"
      class="related-docs cursor-pointer border-0 px-3 py-1 facet-row"
    >
      <li>
        <div class="facet-results-scrollable">
          <div
            class="position-relative list-group-item list-group-item-action"
            title="{{ 'msg#preview.compareDocuments' | sqMessage }}"
          >
            <div class="d-flex justify-content-between align-items-baseline">
              <div
                class="text-truncate mr-auto"
                style="z-index: 1"
                title="{{ item.title }}"
                (click)="openPreview(item)"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </li>
    </div>
  </ol>
</ng-container>
