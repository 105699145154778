import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { appConfig } from "../app-config/app-config";
import { SharedCommonService } from "../services/shared-common.service";

@Component({
  selector: "app-hek-and-gcn",
  templateUrl: "./hek-and-gcn.component.html",
  styleUrls: ["./hek-and-gcn.component.scss"],
})
export class HekAndGcnComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sharedCommonService: SharedCommonService,
    private location: Location
  ) {}

  helioTabs: any[] = [{ tabName: "HEK" }, { tabName: "GCN" }];
  groupedData: any = [];
  selectedTab: any;
  eventData: any;
  hekUrl = appConfig.hekUrl;
  gcnUrl = appConfig.gcnUrl;
  iSolSearchUrl = appConfig.iSolSearchUrl;

  ngOnInit(): void {
    this.sharedCommonService.globalNasaLoader.next(true);
    this.route.queryParams.subscribe((params) => {
      const tab = params["tab"];

      if (tab) {
        this.selectedTab = tab; // Set the tab from the URL
      } else {
        this.selectedTab = "HEK"; // Set a default tab if needed
        this.updateQueryParam(this.selectedTab); // Automatically add default tab to URL
      }
    });
    this.getHekAndGcnData();
  }

  getHekAndGcnData() {
    this.sharedCommonService.getHekAndGcnAcronyms(this.selectedTab).subscribe(
      (data) => {
        if (data && data.acronyms) {
          this.eventData = data.acronyms.map((item: any) => {
            return {
              code: item.Key,
              name: item.Value,
              type: item.Type,
            };
          });
          const eventNameData = this.eventData.filter(
            (item) => item.type === "Event Name"
          );
          const instrumentData = this.eventData.filter(
            (item) => item.type === "Instrument"
          );
          const coordinateSystemsData = this.eventData.filter(
            (item) => item.type === "Coordinate System"
          );
          const missionsData = this.eventData.filter(
            (item) => item.type === "Mission/Observatory"
          );

          this.groupedData = {
            eventNameData: this.groupData(eventNameData),
            instrumentData: this.groupData(instrumentData),
            coordinateSystemsData: this.groupData(coordinateSystemsData),
            missionsData: this.groupData(missionsData),
          };
          this.sharedCommonService.globalNasaLoader.next(false);
        }
      },
      (error) => {
        console.error("Error fetching acronyms:", error);
      }
    );
  }

  updateQueryParam(tab: string) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { tab },
      queryParamsHandling: "merge", // Merge with other params if any
    });
  }

  selectCurrentTab(tab) {
    this.selectedTab = tab.tabName;
    this.updateQueryParam(tab.tabName);
    this.getHekAndGcnData();
  }

  groupData(data: any) {
    const half = Math.ceil(data.length / 2);
    return [data.slice(0, half), data.slice(half)];
  }

  goBack() {
    const previousUrl = localStorage.getItem("previousUrl");

    if (previousUrl) {
      this.router.navigateByUrl(previousUrl).then(() => {
        localStorage.removeItem("previousUrl");
      }); // Navigate back to the stored URL
    } else {
      this.location.back(); // Use browser history as a fallback
    }
  }
}
